<template>
    <div id="investor">
        <div class="banner">
            <img :src="require('../../assets/images/service_banner.png')" alt="" class="bg">
            <img :src="require('../../assets/images/1097.png')" alt="" class="side-pic">
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "Customer",
        components: {},
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    }
</script>

<style lang="less" scoped>
    #investor {
        color: #34254C;
        .navbar {
            position: relative;
        }

        .icon-more {
            display: inline-block;
            width: 1.458vw;
            height: 0.9375vw;
            background: url('../../assets/images/about/icon_more.png') no-repeat center center;
            background-size: 100% 100%;
        }

        .banner {
            width: 100%;
            text-align: right;
            position: relative;
            background: url("../../assets/images/1092.png") no-repeat center center;
            background-size: 100% 100%;
            padding-bottom: 20px;
            .bg {
                width: 1334px;
                height: 625px;
            }
            .side-pic {
                position: absolute;
                left: 0;
                bottom: -35%;
                width: 82px;
            }
        }
    }
    @deep: ~'>>>';

    @media screen and (max-width: 750px){
        #investor{
            .banner{
                margin-bottom: 2%;
                overflow:hidden;
                background:url('../../assets/images/phone/module_bg10.png') no-repeat left top;
                background-size: 50%;
                .bg{
                    width: 100%;
                    height: auto;
                    position: relative;
                    left:14%;
                }
                .side-pic{
                    width: 6%;
                    left: -1.5%;
                    bottom: -4%;
                }
            }
        }
    }
</style>